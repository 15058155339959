<script setup lang="ts">
import { useCommonStore } from './store/modules/common'

const commonStore = useCommonStore() // 通用配置
// const { hideMenuItems } = useWxSdk()
const { handleTokenExpiry } = useTokenUtils() // 自定义 Hook 处理 Token

import { initWxSDK } from './utils/common'
const configWxJsApi = async () => {
  await initWxSDK([
    'updateTimelineShareData',
    'updateAppMessageShareData',
    'openLocation',
    'getLocation',
    'scanQRCode',
    'hideMenuItems',
    'showMenuItems',
  ])
}

onLaunch(async () => {
  // 首次启动时，使用封装的 Token 工具处理过期逻辑

  handleTokenExpiry()

  await configWxJsApi()

  commonStore.resetState()

  // 解决原生 tabBar 未隐藏导致有2个 tabBar 的问题

  // uni.hideTabBar({
  //   fail(err) {
  //     console.log('隐藏tabbar失败: ', err)
  //   },
  // })

})

// onShow(() => {
//   hideMenuItems()
// })
</script>

<style lang="scss">
/* stylelint-disable selector-type-no-unknown */
@import '@assets/styles/variable.css';
@import '@assets/styles/global';
@import '@assets/styles/iconfont.css';

@media (min-width: 1024px) {
  html {
    /* 设置page.config.ts不限制最大宽度maxWidth */
    // font-size: calc(16px + (16 / 375) * (100vw - 375px)) !important;
    /* 设置page.config.ts最大宽度maxWidth: 1024px, */
    font-size: calc(16px + (16 / 375) * (1024px - 375px)) !important;
  }
}
/* 隐藏所有滚动条 */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none;
}

:root,
page {
  font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
  color: #333;
  background-color: var(--wat-bg-color);
}
button::after {
  border: none;
}

swiper,
scroll-view {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

// 单行省略，优先使用 unocss: text-ellipsis
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 单行省略2
.ellipsis-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
// 两行省略
.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 三行省略
.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
// 注意 平板下拉刷新行内样式是写死的，这里写在class上可以让px2rem转成rem
:deep(.uni-page-refresh) {
  clip: rect(-84px, 45px, 45px, -5px) !important;
}

//该样式主要用语HouseMap.vue
// 这些样式用于地图上的标记（poi）时，标记内容是动态生成的 HTML 字符串，不在 Vue 模板中直接渲染，无法使用 scoped 样式。全局样式确保 .map-poi-talk-bubble-default 和 .map-poi-talk-bubble-selected 能应用于动态生成的内容。
.map-poi-talk-bubble-default {
  --h: 8px; // CSS 变量定义，单位需要在这里声明
  position: relative;
  z-index: 3;
  padding: 5px 10px;
  font-size: 14px;
  white-space: nowrap; /* 禁止换行 */
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
  transform: translate(-50%, calc(-100% - var(--h)));

  &::before {
    position: absolute;
    bottom: calc(-1 * var(--h)); /* 正确的变量引用 */
    left: 50%;
    z-index: -1;
    width: 0;
    height: 0;
    content: '';
    border-top: var(--h) solid #fff !important; /* 边框颜色 */
    border-right: var(--h) solid transparent;
    border-left: var(--h) solid transparent;
    transform: translateX(-50%);
  }
}

.map-poi-talk-bubble-selected {
  --h: 8px; // CSS 变量定义，单位需要在这里声明
  position: relative;
  z-index: 3;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap; /* 禁止换行 */
  background: var(--wat-main-color) !important;
  border-radius: 8px;
  opacity: 0.9;
  transform: translate(-50%, calc(-100% - var(--h)));

  &::before {
    position: absolute;
    bottom: calc(-1 * var(--h)); /* 正确的变量引用 */
    left: 50%;
    z-index: -1;
    width: 0;
    height: 0;
    content: '';
    border-top: var(--h) solid var(--wat-main-color) !important; /* 边框颜色 */
    border-right: var(--h) solid transparent;
    border-left: var(--h) solid transparent;
    transform: translateX(-50%);
  }
}
</style>
