import { defineStore } from 'pinia'
import { ref } from 'vue'
// 设置默认类别
const DEFAULT_CATEGORY = '060000' // 默认类别为 "购物"
// 租房周边信息状态
export const useHouseMapStore = defineStore(
  'houseMap',
  () => {
    const state = ref({
      houses: {}, // {  poiDataByCategory: {}, activeCategory: '', selectedPoi: {} }
      activeHouseId: null, // 当前选中的房子 ID
      activeHouseLoc: {
        latitude: 113.392532,
        longitude: 22.517007,
        name: null,
        address: null,
      },
      poiCategories: [
        { name: '购物', code: '060000', icon: 'shopping-bag-f5a6ogd5' },
        { name: '教育', code: '141200', icon: 'school' },
        { name: '医院', code: '090000', icon: 'hospital-four' },
        { name: '公交', code: '150700', icon: 'bus-one' },
        { name: '银行', code: '160100', icon: 'bank' },
        // { name: '餐饮', code: '050000', icon: 'chopsticks-fork-f5a6h28g' },
      ],
    })

    const initializeHouseData = (houseId) => {
      if (!state.value.houses[houseId]) {
        state.value.houses[houseId] = {
          poiDataByCategory: {}, // 周边信息按类别存储
          activeCategory: DEFAULT_CATEGORY, // 设置默认类别
          selectedPoi: null, // 当前选中的 POI
        }
      }
    }

    // 设置某个房子的周边信息
    const setPoiDataForHouse = (houseId, category, poiList) => {
      if (!state.value.houses[houseId]) {
        state.value.houses[houseId] = {
          poiDataByCategory: {},
          activeCategory: null,
          selectedPoi: null,
        }
      }
      state.value.houses[houseId].poiDataByCategory[category] = poiList
    }

    // 设置当前激活的房子
    const setActiveHouse = (houseId, location) => {
      state.value.activeHouseId = houseId
      state.value.activeHouseLoc = location
      // 如果房子没有 activeCategory，设置为默认类别
      const house = state.value.houses[houseId]
      if (house && !house.activeCategory) {
        house.activeCategory = DEFAULT_CATEGORY
      }
      return true // 明确返回完成标志
    }

    // 获取当前房子的状态
    const getActiveHouse = () => {
      return state.value.houses[state.value.activeHouseId] || null
    }
    // 获取当前激活房子的地理位置信息
    const getHouseLoc = () => {
      return state.value.activeHouseLoc
    }
    // 获取当前房子的某个分类的周边信息
    const getActivePoiList = () => {
      const activeHouse = getActiveHouse()
      if (activeHouse && activeHouse.activeCategory) {
        return activeHouse.poiDataByCategory[activeHouse.activeCategory] || []
      }
      return []
    }

    // 设置当前房子激活的分类
    const setActiveCategoryForHouse = (category) => {
      const activeHouse = getActiveHouse()
      if (activeHouse) {
        activeHouse.activeCategory = category
      }
    }

    // 获取某房子某分类的周边数据（如果不存在，则从接口获取）
    const fetchPoiDataForHouse = async (houseId, category = DEFAULT_CATEGORY, fetchPoiData) => {
      try {
        initializeHouseData(houseId) // 确保房子数据结构已初始化
        const house = state.value.houses[houseId]
        if (house?.poiDataByCategory[category]?.length) {
          // 如果有缓存数据，直接返回
          return house.poiDataByCategory[category]
        }

        // 否则从接口获取
        const poiList = await fetchPoiData(category)
        setPoiDataForHouse(houseId, category, poiList) // 保存到 Pinia
        return poiList
      } catch (error) {
        console.error('获取周边数据失败:', error)
        return []
      }
    }
    // 获取当前房子的活跃类
    const getActiveCategory = () => {
      const activeHouse = getActiveHouse()
      return activeHouse?.activeCategory || DEFAULT_CATEGORY
    }

    return {
      state,
      setPoiDataForHouse,
      setActiveHouse,
      getActiveHouse,
      getHouseLoc,
      getActivePoiList,
      setActiveCategoryForHouse,
      getActiveCategory,
      fetchPoiDataForHouse,
    }
  },
  {
    persist: true,
  },
)
