import { useAuthStore } from '@/store'

/**
 * 自定义 Hook: 用于检查 Token 状态并处理过期逻辑
 */
export function useTokenUtils() {
  const authStore = useAuthStore()

  /**
   * 检查 Token 是否过期
   * @returns {boolean} - true 表示 Token 过期，false 表示 Token 有效
   */
  const isTokenExpired = () => {
    const tokenInfo = authStore.authInfo.tokenInfo

    if (!tokenInfo) {
      return true // 如果没有 Token 信息，视为过期
    }

    const currentTime = new Date().getTime()
    return tokenInfo.tokenOutTime < currentTime
  }

  /**
   * 如果 Token 过期，清除用户信息
   */
  const handleTokenExpiry = () => {
    if (isTokenExpired()) {
      authStore.clearAuthInfo()
      console.error('Token 已过期，用户信息已清除')
    }
  }

  const getToken = (): string | null => {
    if (isTokenExpired()) {
      handleTokenExpiry()
      return null // 返回 null 表示 Token 已失效
    }
    return authStore.authInfo.tokenInfo?.token || null
  }

  return {
    isTokenExpired,
    handleTokenExpiry,
    getToken,
  }
}
